<!-- <header>
    <h1></h1>
</header> -->

<ng-container *ngFor="let n of news">
    <div class="row">
        <div class="col">
            <h3 class="title color-{{ n.category }}" [innerHtml]="n.title | safeHtml"></h3>
            <div class="box content">
                <div [innerHtml]="n.content | safeHtml"></div>
            </div>
        </div>
    </div>
</ng-container>
