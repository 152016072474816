<div class="table-wrapper">
    <!-- <div class="toolbar" *ngIf="!disableSettings">
        <div class="toolbar-right">
            <button mat-icon-button type="button" (click)="openSettings()">
                <mat-icon>settings</mat-icon>
            </button>
        </div>
    </div> -->

    <!-- Should contain <app-table></app-table> -->
    <ng-content></ng-content>
</div>
