import { MatLegacyTable as MatTable } from "@angular/material/legacy-table";
import { TableColumn } from "./table-column";
import { ITableColumnLayoutEntry } from "./table-column-layout-entry.model";
import { Observable, Subject, Subscription } from "rxjs";

export class TableColumnLayout {
    constructor(columns: TableColumn[]) {
        columns.forEach(column => this.addColumn(column));
    }

    private readonly _columnEntries: ITableColumnLayoutEntry[] = [];
    private readonly _stateChanges: Subject<void> = new Subject<void>();

    private readonly _subscriptions: Subscription[] = [];

    get displayColumns(): TableColumn[] {
        return this.sortedColumns.filter(column => column.settings.display);
    }

    get hiddenDisplayColumnNames(): string[] {
        return this.hiddenDisplayColumns.map(column => column.columnDef.name);
    }

    get hiddenDisplayColumns(): TableColumn[] {
        return this.sortedColumns.filter(column => column.settings.display && column.settings.hidden);
    }

    get sortedColumns(): TableColumn[] {
        return this._columnEntries.sort((a, b) => this.sort(a, b))
            .map(columnEntry => columnEntry.column);
    }

    get stateChanges(): Observable<void> {
        return this._stateChanges.asObservable();
    }

    get visibleDisplayColumnNames(): string[] {
        return this.visibleColumns.map(column => column.columnDef.name);
    }

    get visibleColumns(): TableColumn[] {
        return this.sortedColumns.filter(column => column.settings.display && !column.settings.hidden);
    }


    /**
     * Add a column.
     *
     * If {@link index} is specified, shifts all entries with greater than or equal
     * index by one position and column is added in the specified {@link index}.
     *
     * If {@link index} is not specified, the column is added as last.
     *
     * @param column Column to add.
     * @param index Index of the column.
     */
    addColumn(column: TableColumn, index: number = -1): void {
        if (index < 0)
            this._columnEntries.push(this.getEntry(column, this._columnEntries.length));
        else {
            this._columnEntries.filter(columnEntry => columnEntry.index >= index)
                .forEach(columnEntry => columnEntry.index += 1)

            this._columnEntries.push(this.getEntry(column, index));
        }

        this._subscriptions.push(
            column.settings.stateChanges.subscribe(() => this._stateChanges.next()));

        this._stateChanges.next();
    }

    // This should not be in this class .
    // (A layout should not know how to apply itself to a table).
    /**
     * Apply this layout to a table.
     *
     * @param table Table to apply the layout to.
     */
    applyToTable(table: MatTable<any>): void {
        if (table._contentColumnDefs && table._contentColumnDefs.length > 0)
            table._contentColumnDefs.forEach(columnDef => table.removeColumnDef(columnDef));

        this.displayColumns.forEach(column => table.addColumnDef(column.columnDef));
    }

    destroy(): void {
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /**
     * Set index for a table column.
     *
     * @param column Table column for which to set the index for.
     * @param index Index to set.
     */
    setIndex(column: TableColumn, index: number): void {
        const currentColumnEntry: ITableColumnLayoutEntry = this.findEntry(column);

        const direction: number = currentColumnEntry.index < index ? 1 : -1;

        for (let i = 0; i < this._columnEntries.length; i++) {
            const columnEntry: ITableColumnLayoutEntry = this._columnEntries[i];

            if (columnEntry == currentColumnEntry)
                continue;

            if (direction == 1 && columnEntry.index <= index && columnEntry.index > currentColumnEntry.index)
                columnEntry.index -= 1;
            else if (columnEntry.index >= index && columnEntry.index < currentColumnEntry.index)
                columnEntry.index += 1;
        }

        currentColumnEntry.index = index;

        this._stateChanges.next();
    }

    /**
     * Set index for table colum {@link column} of table colum {@link of}.
     *
     * Causes all in-between columns to shift indices.
     *
     * @param column Column to set index to.
     * @param of Column from which to set index.
     */
    setIndexOf(column: TableColumn, of: TableColumn): void {
        const ofColumnEntry: ITableColumnLayoutEntry = this.findEntry(of);

        this.setIndex(column, ofColumnEntry.index);
    }

    private findEntry(column: TableColumn): ITableColumnLayoutEntry | undefined {
        return this._columnEntries.find(columnEntry => columnEntry.column == column);
    }

    private findEntryByIndex(index: number): ITableColumnLayoutEntry | undefined {
        return this._columnEntries.find(columnEntry => columnEntry.index == index);
    }

    private getEntry(column: TableColumn, index: number): ITableColumnLayoutEntry {
        return <ITableColumnLayoutEntry>{
            column: column,
            index: index
        };
    }

    private sort(a: ITableColumnLayoutEntry, b: ITableColumnLayoutEntry): number {
        return a.index >= b.index ? 1 : -1;
    }
}
