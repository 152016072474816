<app-select-field *ngIf="isEdit" [hasEmptyOption]="hasEmptyOption" [searchEnabled]="searchEnabled"
    [clearEnabled]="clearEnabled" [multiple]="multiple" [displayTemplate]="displayTemplate"
    [optionTemplate]="optionTemplate" [optionHeaderTemplate]="optionHeaderTemplate" [triggerTemplate]="triggerTemplate"
    [options]="options" [separator]="separator" (searchChange)="searchChange.emit($event)" [inline]="inline"
    [label]="label" [formControl]="control" (ngModelChange)="setValue($event)">
</app-select-field>

<app-value-field *ngIf="!isEdit" [displayClass]="displayClass" [displayTemplate]="multiple
        ? (displayTemplate ? displayTemplate : multiTemplate)
        : (displayTemplate ? displayTemplate : defaultTemplate)" [inline]="inline" [label]="label"
    [value]="displayValue">
</app-value-field>

<ng-template #defaultTemplate let-value="value">{{ value | translateProp }}</ng-template>

<ng-template #multiTemplate let-value="value">
    <ng-container *ngFor="let n of value">
        {{ value | translateProp }}
        <span class="separator">{{separator}} </span>
    </ng-container>
</ng-template>
