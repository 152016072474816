import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit } from '@angular/core';
import { NgForm, ControlContainer } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { CustomErrorStateMatcher } from '@core/custom-error-state-matcher';
import { Util } from '@zj/paka-client';

export interface IFileInput {
    file?: File;
    fileName?: string;
    valid?: boolean;
}

export interface IFileSelect {
    file: File;
    isValid?: boolean;
    originalEvent: any;
}

export interface IMultiFileSelect {
    files: File[];
    originalEvent: any;
}

/**
 * Form field file component in angular material design
 */
@Component({
    selector: 'app-file-input',
    templateUrl: './file-input.component.html',
    styleUrls: ['./file-input.component.css'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    host: { class: 'mat-form-field' }
})
export class FileInputComponent implements OnInit {
    @Input() file: IFileInput = <IFileInput>{};
    @Input() label: string;
    @Input() accept: string = '';
    @Input() maxSize: number = 10 * 1024 * 1024;
    @Input() required: boolean;
    @Input() isLoading: boolean;
    @Input() showDownload: boolean = true;
    @Input() showRemove: boolean = true;
    @Input() multiple: boolean;
    @Input() disabled: boolean;
    @Input() hint: string;
    @Input() onMultipleSelect: (files: File[]) => void;
    @Input() onRemove: (file: IFileInput) => Observable<boolean> = () => of(true);

    @Output() select: EventEmitter<IFileSelect> = new EventEmitter();
    @Output() multipleSelect: EventEmitter<IMultiFileSelect> = new EventEmitter();
    @Output() remove: EventEmitter<any> = new EventEmitter();
    @Output() download: EventEmitter<any> = new EventEmitter();

    size: number;
    hasMaxSizeError: boolean;
    hasExtensionError: boolean;

    groupName: string;
    inputName: string;

    fileErrorStateMatcher = new CustomErrorStateMatcher(() => {
        return this.hasError;
    }, true);

    @ViewChild('fileInput', { static: true }) private fileInput: ElementRef;

    get hasFile(): boolean {
        return !!(this.file && this.file.fileName);
    }

    get hasError(): boolean {
        return this.hasMaxSizeError || this.hasExtensionError || (this.required && !this.hasFile);
    }

    private static counter = 0;

    ngOnInit() {
        FileInputComponent.counter++;

        this.groupName = `file${FileInputComponent.counter}`;
        this.inputName = `file${FileInputComponent.counter}`;

        if (this.file && this.file.file) {
            this.selectFile(this.file.file, undefined);
        }
    }

    onFileSelect(event: any) {
        this.selectFile(event.target.files[0], event, !this.multiple);

        if (this.multiple) {
            this.multipleSelect.emit({
                originalEvent: event,
                files: [...event.target.files.map]
            });
        }
    }

    removeFile() {
        this.onRemove(this.file).subscribe((result) => {
            if (result) {
                this.hasMaxSizeError = false;
                this.hasExtensionError = false;

                this.file.file = undefined;
                this.file.fileName = undefined;
                this.file.valid = undefined;

                this.fileInput.nativeElement.value = null;
                this.remove.emit();
            }
        });
    }

    downloadFile() {
        this.download.emit(this.file);
    }

    prettySize(size: number): string {
        return Util.prettyFileSize(size);
    }

    private selectFile(file: File, event: any, emit: boolean = true) {
        if (!file) return;

        this.hasMaxSizeError = false;
        this.hasExtensionError = false;

        this.size = file.size;

        if (this.maxSize && file.size > this.maxSize) {
            this.hasMaxSizeError = true;
        }

        const extension = file.name.substring(file.name.lastIndexOf('.') + 1);

        if (
            this.accept &&
            this.accept.indexOf(extension.toLowerCase()) < 0 &&
            this.accept.indexOf('.' + extension.toLowerCase()) < 0
        ) {
            this.hasExtensionError = true;
        }

        this.file = this.file || <IFileInput>{};
        this.file.file = file;
        this.file.fileName = file.name;
        this.file.valid = !this.hasError;

        if (emit) {
            this.select.emit({
                file,
                isValid: !this.hasError,
                originalEvent: event
            });
        }
    }
}
