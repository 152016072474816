<div class="attachments">
    <div class="group attachments-file" *ngIf="model.files.length">
        <h5 class="group-title" *ngIf="!singleType">
            {{ "attachments.files" | translate }}
        </h5>
        <div class="row" *ngFor="let n of model.files">
            <div class="col-{{ n.file ? 10 : 12 }}">
                <div class="li-row">
                    <div class="li-row-content">
                        <span
                            *ngIf="n.extension"
                            class="li-row-icon fiv-sqo fiv-icon-{{
                                n.extension.substring(1)
                            }}"
                        ></span>
                        <span class="li-row-body">
                            <a
                                href="#"
                                *ngIf="n.fileId && downloadFileFn"
                                (click)="
                                    $event.preventDefault(); downloadFile(n)
                                "
                                >{{ n.name }}</a
                            >
                            <span *ngIf="!n.fileId">{{ n.name }}</span>
                        </span>
                        <span class="li-row-action">
                            <button
                                mat-icon-button
                                (click)="remove(n)"
                                tabindex="-1"
                                *ngIf="
                                    filesEnabled &&
                                    (!n.uploadStatus || n.uploadStatus.error)
                                "
                            >
                                <mat-icon class="mat-icon-fix">clear</mat-icon>
                            </button>
                            <span
                                class="status"
                                *ngIf="
                                    n.uploadStatus &&
                                    n.uploadStatus.percentDone > 0 &&
                                    !n.uploadStatus.error
                                "
                                [class.text-success]="
                                    n.uploadStatus.percentDone === 100
                                "
                                >{{ n.uploadStatus.percentDone }}%</span
                            >
                        </span>
                    </div>
                    <div
                        class="li-row-error"
                        *ngIf="n.maxSizeError || n.extensionError"
                    >
                        <mat-error *ngIf="n.maxSizeError">{{
                            "file.maxSizeError"
                                | translate
                                | formatString
                                    : [
                                          prettySize(n.file.size),
                                          prettySize(maxSize)
                                      ]
                        }}</mat-error>
                        <mat-error *ngIf="n.extensionError">{{
                            "file.extensionError"
                                | translate
                                | formatString : accept
                        }}</mat-error>
                    </div>
                </div>
            </div>
            <div class="col-2 extra" *ngIf="n.file">
                <span class="preview">
                    <img
                        *ngIf="n.preview"
                        [src]="n.preview | safeUrl"
                        (error)="onPreviewError(n)"
                    />
                </span>
            </div>
        </div>
    </div>

    <div class="group attachments-link" *ngIf="model.links.length">
        <h5 class="group-title" *ngIf="!singleType">
            {{ "attachments.links" | translate }}
        </h5>
        <div class="li-row" *ngFor="let n of model.links">
            <div class="li-row-content">
                <mat-icon class="li-row-icon">link</mat-icon>
                <span class="li-row-body" [title]="n.url">
                    <a href="{{ n.url }}" target="_blank">{{ n.url }}</a>
                </span>
                <span class="li-row-action">
                    <button
                        mat-icon-button
                        (click)="editLink(n)"
                        *ngIf="linksEnabled"
                    >
                        <mat-icon class="mat-icon-fix">edit</mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        (click)="remove(n)"
                        *ngIf="linksEnabled"
                    >
                        <mat-icon class="mat-icon-fix">clear</mat-icon>
                    </button>
                </span>
            </div>
        </div>
    </div>

    <div class="action-row">
        <button
            mat-stroked-button
            *ngIf="filesEnabled"
            (click)="addFile()"
            tabindex="-1"
        >
            <mat-icon>add</mat-icon>
            {{ "attachments.addFile" | translate }}
        </button>

        <button
            mat-stroked-button
            *ngIf="linksEnabled"
            (click)="addLink()"
            tabindex="-1"
        >
            <mat-icon>add</mat-icon>
            {{ "attachments.addLink" | translate }}
        </button>
    </div>

    <input
        *ngIf="hasError"
        [ngModel]="error"
        required
        name="error"
        style="display: none"
    />
    <input
        #upload
        name="upload"
        type="file"
        (change)="onFileSelect($event); upload.value = ''"
        [accept]="accept"
        multiple
        style="display: none"
    />
</div>
