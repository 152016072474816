<div class="site-header">
    <div class="container">
        <div class="logo-container">
            <a [routerLink]="['/']" class="logo">
                <img src="assets/img/logo.svg" alt="logo" />
            </a>
        </div>

        <div class="main">
            <nav class="primary">
                <ul *ngIf="isAuthenticated" class="nav-list">
                    <li class="nav-item" *ngFor="let n of primaryMenus" [class.current]="n.isOpened">
                        <a href="#" (click)="$event.preventDefault(); openPrimaryMenu(n)" class="sub-nav-toggle">
                            {{ n.text || ("nav." + n.name | translate) }}
                        </a>
                    </li>

                    <li class="nav-item" *ngFor="let n of primaryLinks">
                        <a [routerLink]="[n.url]" class="nav-link" [routerLinkActive]="['current']">
                            {{ n.text || ("nav." + n.name | translate) }}
                        </a>
                    </li>
                </ul>
            </nav>

            <nav class="secondary">
                <ul class="nav-list">
                    <li class="nav-item">
                        <a href="tel:{{ 'common.phoneNumber' | translate }}" class="nav-link nowrap">
                            <mat-icon>phone</mat-icon>
                            {{ "common.phoneNumber" | translate }}
                        </a>
                    </li>

                    <li class="nav-item languageMenu" *ngIf="languageMenu">
                        <a href="#" (click)="$event.preventDefault(); toggleMenu(languageMenu)" class="sub-nav-toggle"
                            [class.current]="languageMenu.isOpened">
                            <span>{{ languageMenu.text }}</span>
                        </a>

                        <ul class="sub-nav" *ngIf="languageMenu.isOpened">
                            <li *ngFor="let n of languageMenu.getNavLinks()">
                                <a href="#" (click)="$event.preventDefault(); switchLanguage(n.value)">
                                    <span>{{ n.name }}</span>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item" *ngIf="!isAuthenticated">
                        <a [routerLink]="['/auth/login']" class="nav-link">
                            <mat-icon>login</mat-icon>
                            <span>{{ "nav.login" | translate }}</span>
                        </a>
                    </li>

                    <ng-container *ngIf="isAuthenticated">
                        <li class="nav-item" *ngIf="clientMenu">
                            <a href="#" (click)="$event.preventDefault(); toggleMenu(clientMenu)" class="sub-nav-toggle"
                                [class.current]="clientMenu.isOpened">
                                <span class="client-name">
                                    {{ clientMenu.text }}
                                </span>
                            </a>

                            <ul class="sub-nav" *ngIf="clientMenu.isOpened">
                                <li *ngFor="let n of clientMenu.getNavLinks()">
                                    <a href="#" (click)="$event.preventDefault(); changeClient(n.value)">
                                        <span>{{ n.name }}</span>
                                    </a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item" id="nav-item-user">
                            <a href="#" (click)="$event.preventDefault(); toggleMenu(userMenu)" class="sub-nav-toggle"
                                [class.current]="userMenu.isOpened">
                                <mat-icon>person</mat-icon>
                                <span class="user">
                                    <span class="user-display-name">{{ userDisplayName }}</span>
                                    <span *ngIf="userDisplayName != userName" class="user-name">{{ userName }}</span>
                                </span>
                            </a>
                            <app-header-menu *ngIf="userMenu.isOpened" [items]="userMenu.items"
                                [style]="'align-right'"></app-header-menu>
                        </li>
                    </ng-container>
                </ul>
            </nav>
        </div>
    </div>
    <ng-container *ngFor="let n of primaryMenus">
        <app-sub-header *ngIf="n.isOpened" [menu]="n" (toggleMenu)="toggleMenu($event)"></app-sub-header>
    </ng-container>
</div>

<div class="client-notifications" *ngIf="clientNotifications && clientNotifications.nonSubmittedContracts.length">
    <div class="container">
        <div class="row" style="flex: auto;">
            <div class="col" *ngFor="let n of clientNotifications.nonSubmittedContracts">
                <div class="alert alert-danger">
                    {{ "client.notSubmittedContract" | translate | formatString : [n.category, n.year, n.quarter] }}
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="site-header">
    <div class="container">
        <div class="mobile">
          <div class="logo-container">
            <a [routerLink]="['/']" class="logo">
              <img src="assets/img/logo.svg" alt="logo">
            </a>
          </div>
          <button type="button" (click)="mobileNavOpened = !mobileNavOpened" class="mobile-toggle">
            <mat-icon>{{mobileNavOpened ? 'clear' : 'menu'}}</mat-icon>
          </button>
        </div>

        <div class="main">
            <div class="logo-container">
                <a [routerLink]="['/']" class="logo">
                    <img src="assets/img/logo.svg" alt="logo" />
                </a>
            </div>

            <nav class="main-menu">
                <ul class="nav-list">
                    <li
                        class="nav-item"
                        *ngFor="let n of getVisible(mainLinks)"
                    >
                        <a
                            class="nav-link"
                            [routerLink]="[n.url]"
                            [routerLinkActive]="['current']"
                        >
                            <span>{{ "nav." + n.name | translate }}</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div> -->
