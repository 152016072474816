<div role="group" class="localization-subject-container wrapper-transform-none"
     [formGroup]="parts"
     [attr.aria-labelledby]="_formField.getLabelId()"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
    <mat-select #locale formControlName="locale" aria-label="Locale" class="wrapper-transform-none" (selectionChange)="selectionChange($event)">
        <mat-option *ngFor="let locale of supportedLocales" [value]="locale">{{ locale }}</mat-option>
    </mat-select>
    <span class="localization-subject-input-spacer">|</span>
    <input #value class="localization-subject-input-element month"
           formControlName="value"
           aria-label="Value"
           (input)="input()">
</div>

<ng-container *ngFor="let locale of supportedLocales">
    <div *ngIf="locale != activeLocale" class="localization-list-item">
        <span>{{ locale }}</span>
        <span class="localization-subject-input-spacer">|</span>
        <span>{{ localizationSubject.getValue(locale) }}</span>
    </div>
</ng-container>
