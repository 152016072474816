<div role="group" class="service-date-input-container" [formGroup]="parts"
    [attr.aria-labelledby]="_formField.getLabelId()" (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)">

    <input class="service-date-input-element" formControlName="year" size="4" maxLength="4" aria-label="Year"
        appOnlyNumber #year (input)="onChange(value)">

    <span class="service-date-input-spacer">/</span>

    <mat-select #locale formControlName="month" aria-label="Month"
        class="wrapper-transform-none service-date-month-input" (selectionChange)="onChange(value)">
        <mat-option *ngFor="let quarter of [1, 2, 3, 4]" [value]="quarter">{{ quarter | appRomanQuarter }}</mat-option>
    </mat-select>
</div>
