import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export class Util {
    /**
     * Create a new TranslateHttpLoader that supports {@link baseHref}.
     *
     * @param http HTTP client to use.
     * @param baseHref Base HREF to support.
     *
     * @returns New {@link TranslateHttpLoader} with configured support for {@link baseHref}.
     *
     * @example
     * TranslateModule.forRoot({
     *      loader: {
     *          provide: TranslateLoader,
*               useFactory: (http: HttpClient, location: LocationStrategy) => Util.createTranslateHttpLoader(http, location.getBaseHref()),
     *          deps: [HttpClient, LocationStrategy]
     *      }
     *  }),
     */
    static createTranslateHttpLoader(http: HttpClient, baseHref: string) {
        return new TranslateHttpLoader(http, baseHref + 'assets/i18n/');
    }
}
