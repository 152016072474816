import { Component, ViewChild } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyColumnDef as MatColumnDef } from '@angular/material/legacy-table';
import { Subject } from 'rxjs';

export class RowSelectionChange {
    constructor(public readonly row: any, public readonly value: boolean) { }
}

@Component({
    selector: 'app-selection-column-def',
    templateUrl: './selection-column-def.component.html',
    styleUrls: ['./selection-column-def.component.scss']
})
export class SelectionColumnDefComponent {
    @ViewChild(MatColumnDef, { static: true }) columnDef: MatColumnDef;

    allSelected: boolean = false;
    isSelected: (row: any) => boolean = () => false;
    selectionAllChange: Subject<boolean> = new Subject<boolean>();
    selectionChange: Subject<RowSelectionChange> = new Subject<RowSelectionChange>();

    readonly columnName: string = '_selected';
    readonly columnLabel: string = 'table.selected';

    protected change(row: any, event: MatCheckboxChange): void {
        this.selectionChange.next(new RowSelectionChange(row, event.checked));
    }

    protected changeAll(event: MatCheckboxChange): void {
        this.selectionAllChange.next(event.checked);
    }
}
