import { CommonModule, LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/material.module';
import { AlertComponent } from './components/alert/alert.component';
import { ConfirmComponent } from './components/alert/confirm.component';
import { AttachmentsComponent } from './components/inputs/attachments/attachments.component';
import { FileInputComponent } from './components/inputs/file-input/file-input.component';
import { HyperlinkEditComponent } from './components/hyperlink/edit.component';
import { PageNotFoundComponent } from './components/pages/not-found.component';
import { SearchInputComponent } from './components/inputs/search-input/search-input.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TextMoreLessComponent } from './components/text-more-less/text-more-less.component';
import { TreeComponent } from './components/tree/tree.component';
import { ArrayJoinPipe } from './pipes/array-join.pipe';
import { AppDatePipe } from './pipes/date.pipe';
import { FormatStringPipe } from './pipes/format-string.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { PagePipe } from './pipes/page.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { TranslatePropertyPipe } from './pipes/translate-property.pipe';
import { FieldErrorDirective } from './directives/field-error.directive';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { TextEditFieldComponent } from './components/fields/text-edit-field/text-edit-field.component';
import { SelectEditFieldComponent } from './components/fields/select-edit-field/select-edit-field.component';
import { DateFieldComponent } from './components/fields/date-field/date-field.component';
import { RomanQuarterDirective } from './directives/roman-quarter.directive';
import { AutocompleteFieldComponent } from './components/fields/autocomplete-field/autocomplete-field.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ServiceDateFieldComponent } from './components/fields/service-date-field/service-date-field.component';
import { ServiceDateFieldInputComponent } from './components/fields/service-date-field/service-date-field-input/service-date-field-input.component';
import { IntegerOnlyDirective } from './directives/integer-only.directive';
import { LocalizationSubjectInputComponent } from './components/localized-string-input/localization-subject-input.component';
import { PickLocalizedStringPipe } from './pipes/localize-data.pipe';
import { CheckboxFieldComponent } from './components/fields/checkbox-field/checkbox-field.component';
import { ValueFieldComponent } from './components/fields/value-field/value-field.component';
import { NumberFieldComponent } from './components/fields/number-field/number-field.component';
import { OnlyNumberDirective } from './directives/only-number.directive/only-number.directive';
import { RomanQuarterPipe } from './pipes/roman-quarter.pipe';
import { SelectFieldComponent } from './components/fields/select-field/select-field.component';
import { TextFieldComponent } from './components/fields/text-field/text-field.component';
import { Util } from '@core/util';
import { ClientModule as PakaClientModule } from '@zj/paka-client/clients';
import { UserModule as PakaUserModule } from '@zj/paka-client/users';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/legacy-dialog';
import { PasswordFieldComponent } from './components/fields/password-field/password-field.component';
import { SelectionColumnDefComponent } from './table/components/selection-column-def/selection-column-def.component';
import { TableComponent } from './table/components/table/table.component';
import { TableSettingsComponent } from './table/components/table-settings/table-settings.component';
import { TableWrapperComponent } from './table/components/table-wrapper/table-wrapper.component';
import { SelectableRowsDirective } from './table/directives/selectable-rows.directive';
import { TableColumnDirective } from './table/directives/column-label.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';

const COMPONENTS = [
    AlertComponent,
    ConfirmComponent,
    AttachmentsComponent,
    FileInputComponent,
    HyperlinkEditComponent,
    PageNotFoundComponent,
    SpinnerComponent,
    TextMoreLessComponent,
    TreeComponent,
    SearchInputComponent,
    TextEditFieldComponent,
    SelectEditFieldComponent,
    DateFieldComponent,
    ServiceDateFieldComponent,
    ServiceDateFieldInputComponent,
    AutocompleteFieldComponent,
    LocalizationSubjectInputComponent,
    CheckboxFieldComponent,
    ValueFieldComponent,
    NumberFieldComponent,
    PasswordFieldComponent,
    SelectFieldComponent,
    TextFieldComponent,
    SelectionColumnDefComponent,
    TableComponent,
    TableSettingsComponent,
    TableWrapperComponent
];

@NgModule({
    imports: [
        DragDropModule,
        MaterialModule,
        CommonModule,
        PakaUserModule,
        PakaClientModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient, location: LocationStrategy) => Util.createTranslateHttpLoader(http, location.getBaseHref()),
                deps: [HttpClient, LocationStrategy]
            }
        }),
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        NgxMatSelectSearchModule
    ],
    exports: [
        ...COMPONENTS,
        SafeHtmlPipe,
        SafeUrlPipe,
        AppDatePipe,
        FormatStringPipe,
        ArrayJoinPipe,
        OrderByPipe,
        PagePipe,
        TranslatePropertyPipe,
        PickLocalizedStringPipe,
        RomanQuarterPipe,
        FieldErrorDirective,
        OnlyNumberDirective,
        AutoFocusDirective,
        RomanQuarterDirective,
        IntegerOnlyDirective,
        SelectableRowsDirective,
        TableColumnDirective
    ],
    declarations: [
        ...COMPONENTS,
        SafeHtmlPipe,
        SafeUrlPipe,
        AppDatePipe,
        FormatStringPipe,
        ArrayJoinPipe,
        OrderByPipe,
        PagePipe,
        TranslatePropertyPipe,
        PickLocalizedStringPipe,
        RomanQuarterPipe,
        FieldErrorDirective,
        OnlyNumberDirective,
        AutoFocusDirective,
        RomanQuarterDirective,
        IntegerOnlyDirective,
        NumberFieldComponent,
        SelectableRowsDirective,
        TableColumnDirective
    ],
    providers: [
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true } }
    ]
})
export class SharedModule { }
