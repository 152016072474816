import { Component, OnInit } from '@angular/core';
import { AppService } from '@shared/data-access/app/app.service';
import { TextTemplateClient, TextTemplateCode } from '@zj/paka-client/text-templates';
import { Category } from '@zj/paka-client';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    constructor(
        private app: AppService,
        private textTemplateClient: TextTemplateClient
    ) { }

    protected news: { title: string, content: string, category: Category }[];

    private readonly categoryTemplates = {
        [Category.IA]: { title: TextTemplateCode.IaNewsTitle, content: TextTemplateCode.IaNews },
        [Category.EEIA]: { title: TextTemplateCode.EeiaNewsTitle, content: TextTemplateCode.EeiaNews },
        [Category.TKS]: { title: TextTemplateCode.TksNewsTitle, content: TextTemplateCode.TksNews },
        [Category.VKP]: { title: TextTemplateCode.VkpNewsTitle, content: TextTemplateCode.VkpNews }
    };

    ngOnInit(): void {
        this.loadNews(this.app.currentClient.categories);
    }

    protected loadNews(categories: Category[]): void {
        const textTemplateCodes = categories.map(category => this.categoryTemplates[category]);

        const loading = this.app.showLoading();

        this.textTemplateClient.get(textTemplateCodes.flatMap(t => [t.title, t.content])).subscribe(textTemplates => {
            this.app.hideLoading(loading);

            this.news = categories.map(category => {
                const textTemplateCodes = this.categoryTemplates[category];

                const title = this.app.pickLocalizedString(textTemplates
                    .find(textTemplate => textTemplate.code == textTemplateCodes.title).contentLocalizedValues);

                const content = this.app.pickLocalizedString(textTemplates
                    .find(textTemplate => textTemplate.code == textTemplateCodes.content).contentLocalizedValues);

                return { title, content, category };
            });
        });
    }
}
