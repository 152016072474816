import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';

export class CustomErrorStateMatcher implements ErrorStateMatcher {
    constructor(private hasError: (control?: UntypedFormControl) => boolean, private mustTouch: boolean = true, private mustBeDirty: boolean = true) { }

    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control && form && (!this.mustTouch || control.touched) && (!this.mustBeDirty || control.dirty) && this.hasError(control);
    }
}
