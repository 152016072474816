<fieldset ngModelGroup="{{ groupName }}">
    <mat-form-field class="file-form-field inner">
        <mat-label>{{ label }}</mat-label>
        <input
            matInput
            (click)="fileInput.click(); proxyInput.blur()"
            [ngModel]="
                hasFile ? file.fileName : ('file.placeholder' | translate)
            "
            #proxyInput
            class="proxy-input"
            readonly
            [required]="required"
            [disabled]="disabled"
            name="{{ inputName }}"
            [errorStateMatcher]="fileErrorStateMatcher"
        />
        <span matSuffix class="multi-suffix">
            <button
                mat-icon-button
                *ngIf="
                    showDownload && !file.file && file.fileName && !isLoading
                "
                (click)="downloadFile()"
            >
                <mat-icon>get_app</mat-icon>
            </button>
            <button
                mat-icon-button
                *ngIf="showRemove && hasFile && !isLoading"
                (click)="removeFile()"
            >
                <mat-icon>clear</mat-icon>
            </button>
        </span>
        <button matSuffix mat-icon-button *ngIf="isLoading" disabled>
            <app-spinner></app-spinner>
        </button>
        <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
        <mat-error *ngIf="hasMaxSizeError">{{
            "file.maxSizeError"
                | translate
                | formatString : [prettySize(size), prettySize(maxSize)]
        }}</mat-error>
        <mat-error *ngIf="hasExtensionError">{{
            "file.extensionError" | translate | formatString : accept
        }}</mat-error>
    </mat-form-field>
    <input
        name="file"
        type="file"
        #fileInput
        (change)="onFileSelect($event)"
        [accept]="accept"
        [multiple]="multiple"
        style="display: none"
    />
</fieldset>
