<mat-form-field>
    <mat-label>{{ label }}</mat-label>
    <input matInput [formControl]="searchInput" maxlength="100" />
    <mat-icon matSuffix *ngIf="!searchInput.value">search</mat-icon>
    <mat-icon
        matSuffix
        *ngIf="searchInput.value"
        (click)="clear()"
        class="clear"
        >clear</mat-icon
    >
</mat-form-field>
