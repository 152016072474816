import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { AppService } from '@shared/data-access/app/app.service';
import { AuthService } from '@shared/data-access/auth/auth.service';
import { appVersion } from '@version';
import { FontSize } from '@core/font-size';
import { Menu } from '../data-access/models/menu';
import { NavService } from '../data-access/nav.service';
import { User } from '@shared/data-access/auth/models/user';
import { INavLink } from '../data-access/models/nav-link.model';
import { IUserClient } from '@shared/data-access/auth/models/user-profile.model';
import { Subscription, first } from 'rxjs';
import { ParameterClient, ParameterCode } from '@zj/paka-client/parameters';
import { ClientClient, IClientNotificationsResponse, IClientResponseForUserTypeClient } from '@zj/paka-client/clients';
import { Util, supportedLocales } from '@zj/paka-client';

interface ILanguage {
    code: string;
    label: string;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    constructor(
        private app: AppService,
        private auth: AuthService,
        private parameterClient: ParameterClient,
        private router: Router,
        private clientClient: ClientClient,
        private navService: NavService
    ) {
        this.languages = supportedLocales.map(t => ({ code: t.toLowerCase(), label: t }));

        this.languageMenu = new Menu('language',
            this.languages.filter(t => t.code !== this.app.currentLanguage).map(t => <INavLink>{ name: t.label, value: t.code }),
            this.app.currentLanguage.toUpperCase()
        );

        this.menus.push(this.languageMenu);
    }

    readonly appVersion = appVersion;

    protected appTitle: string;
    protected userName: string;
    protected userDisplayName: string;
    protected isAuthenticated: boolean;
    protected isAdmin: boolean;
    protected mobileNavOpened: boolean;

    protected clients: IUserClient[] = [];
    protected clientMenu: Menu;

    protected clientNotifications: IClientNotificationsResponse;

    protected languageMenu: Menu;

    protected readonly fontSizes: FontSize[] = [FontSize.Small, FontSize.Normal, FontSize.Large];
    protected readonly languages: ILanguage[];

    protected readonly userMenu = this.navService.getUserMenu();

    protected menus: Menu[] = [this.userMenu];
    protected primaryMenus: Menu[] = [];
    protected primaryLinks: INavLink[] = [];

    protected fontSize = FontSize.Normal;

    private clientMenuKey: string = 'client';

    private readonly subscriptions: Subscription[] = [];

    ngOnInit() {
        this.loadMenus();

        this.subscriptions.push(this.router.events.subscribe((e) => this.interceptNavigation(e as RouterEvent)));

        this.subscriptions.push(this.app.onDocumentClick().subscribe((e) => {
            if (this.mobileNavOpened) return;

            const opened = [
                ...this.menus.filter((t) => t.isOpened),
                ...this.primaryMenus.map((t) => t.getMenus().filter((t) => t.isOpened))
                    .flat()
            ];

            if (opened.length) {
                if (!e.target.closest('.sub-nav-toggle') && !e.target.closest('.sub-nav')) {
                    opened.forEach((t) => t.close());
                }
            }
        }));

        this.subscriptions.push(this.auth.user$.subscribe((user: User) => {
            this.isAuthenticated = user.isAuthenticated;
            this.isAdmin = user.isAdmin;
            this.userName = user.userName;
            this.userDisplayName = user.displayName;
            this.clients = user.clients;

            if (!this.clients.some(client => client.id == this.auth.currentClient?.id))
                this.removeClient();

            this.loadMenus();
        }));

        this.subscriptions.push(this.auth.currentClient$.subscribe((client) => {
            this.removeClient();

            this.loadMenus();

            if (!client)
                return;

            let navs = this.clients.filter(t => t.id != client.id).map(t => <INavLink>{ name: t.name, value: t.id });
            this.clientMenu = new Menu(this.clientMenuKey, navs, client.name);

            this.menus.push(this.clientMenu);

            this.clientClient.getNotifications(client.id).subscribe((notifications: IClientNotificationsResponse) => {
                this.clientNotifications = notifications;
            });
        }));

        const loading = this.app.showLoading();

        this.parameterClient.get().pipe(first()).subscribe(data => {
            this.app.hideLoading(loading);
            this.appTitle = this.parameterClient.findValue(ParameterCode.AppTitle, data);
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    toggleMenu(menu: Menu): void {
        this.menus.filter((t) => t !== menu).forEach((t) => t.close());
        this.primaryMenus.forEach((t) =>
            t.getMenus().filter((t) => t !== menu).forEach((t) => t.close()));

        menu.toggle();
    }

    openPrimaryMenu(menu: Menu, navigate: boolean = true): void {
        this.primaryMenus.filter((t) => t !== menu).forEach((t) => t.close());
        menu.open();

        if (navigate) {
            const item = menu.items[0];

            if (!(item instanceof Menu))
                this.router.navigate([item.url]);
        }
    }

    switchLanguage(code: string): void {
        this.app.switchLanguage(code);
    }

    changeFontSize(size: FontSize): void {
        this.app.setFontSize(size);
    }

    getVisible(items: INavLink[]): INavLink[] {
        return items && items.filter((t) => !t.visible || t.visible(t));
    }

    changeClient(clientId: number): void {
        if (!this.auth.currentClient)
            return;

        const loading = this.app.showLoading();

        this.auth.changeClient(clientId, false).subscribe(() => {
            this.app.hideLoading(loading);

            location.reload();
        });
    }

    private interceptNavigation(event: RouterEvent) {
        this.mobileNavOpened = false;
        this.menus.forEach((t) => t.close());
        this.primaryMenus.forEach((t) => t.getMenus().forEach((t) => t.close()));

        if (event instanceof NavigationEnd) {
            const url = event.url.split('/').slice(1, 3).join('/').split('?')[0];

            const activeUrlMenu = this.primaryMenus.find(t => t.isActiveByUrl(url));
            const openedMenu = this.primaryMenus.find(t => t.isOpened);

            if (activeUrlMenu === openedMenu)
                return;

            if (activeUrlMenu && !openedMenu)
                this.openPrimaryMenu(activeUrlMenu, false);
            else
                this.primaryMenus.forEach((t) => t.close());
        }
    }

    private loadMenus(): void {
        this.primaryMenus = [...this.navService.getCategoryMenus()];
        this.primaryLinks = [this.navService.getArchivesNavLink(), this.navService.getEInvoiceNavLink()];
    }

    private removeClient(): void {
        let clientMenu = this.menus.find(t => t.name == this.clientMenuKey);
        if (clientMenu) Util.removeItemFromArray(this.menus, clientMenu);

        delete this.clientMenu;
        delete this.clientNotifications;
    }
}
