import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'appRomanQuarter' // "date" pipe is already reserved
})
export class RomanQuarterPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        switch (value) {
            case 1: return 'I';
            case 2: return 'II';
            case 3: return 'III';
            case 4: return 'IV';
            default: return null;
        }
    }
}
