<div class="table-container">
    <table mat-table [dataSource]="dataSource" [multiTemplateDataRows]="multipleDataRows">
        <!-- Table layout definitions -->
        <ng-content></ng-content>

        <ng-container *ngIf="!customRows">
            <tr mat-header-row *matHeaderRowDef="columnLayout.visibleDisplayColumnNames"></tr>
            <tr mat-row *matRowDef="let row; columns: columnLayout.visibleDisplayColumnNames"></tr>
        </ng-container>
    </table>
</div>
