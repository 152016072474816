import { MatLegacyColumnDef as MatColumnDef } from "@angular/material/legacy-table";
import { ITableColumnSettingsParameters, TableColumnSettings } from "./table-column-settings";

export class TableColumn {
    constructor(
        public readonly columnDef: MatColumnDef,
        public readonly label: string,
        settings?: TableColumnSettings | ITableColumnSettingsParameters
    ) {
        if (settings instanceof TableColumnSettings)
            this.settings = settings;
        else
            this.settings = new TableColumnSettings(settings);
    }

    readonly settings!: TableColumnSettings
}
