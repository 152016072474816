import { Directive, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { RowSelectionChange, SelectionColumnDefComponent } from '@shared/table/components/selection-column-def/selection-column-def.component';
import { Subscription } from 'rxjs';
import { TableComponent } from '../components/table/table.component';
import { TableColumn } from '../data-access/table-column';
import { TableColumnSettings } from '../data-access/table-column-settings';
import { Util } from '@zj/paka-client';

@Directive({
    selector: '[appSelectableRows]'
})
export class SelectableRowsDirective implements OnInit, OnDestroy {
    constructor(
        private readonly _table: TableComponent,
        private readonly _viewContainerRef: ViewContainerRef
    ) { }

    selected: any[] = [];

    private _selectionDefComponent: SelectionColumnDefComponent;
    private _selectedAll: boolean = false;

    private readonly _subscriptions: Subscription[] = [];

    set selectedAll(value: boolean) {
        this._selectedAll = value;

        this._selectionDefComponent.allSelected = this._selectedAll;
        this._selectionDefComponent.isSelected = (row) => this.selected.indexOf(row) != -1;
    }

    ngOnInit(): void {
        this._selectionDefComponent = this._viewContainerRef.createComponent(SelectionColumnDefComponent).instance;

        this._selectionDefComponent.selectionAllChange.subscribe(event => this.toggleAll(event));
        this._selectionDefComponent.selectionChange.subscribe(event => this.toggleSelection(event));

        this._table.columnLayoutRestore.subscribe(() => this.addSelectedColumnToTable());
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    reset(): void {
        this.selected = [];
        this.selectedAll = false;
    }

    private addSelectedColumnToTable(): void {
        this._table.columnLayout.addColumn(new TableColumn(this._selectionDefComponent.columnDef, this._selectionDefComponent.columnLabel, new TableColumnSettings({ display: true, locked: true })), 0);
    }

    private toggleAll(value: boolean): void {
        if (value)
            this.selected = [...(this._table.table.dataSource as MatTableDataSource<any>).data];
        else
            this.selected = [];

        this.updateSelectedAll();
    }

    private toggleSelection(event: RowSelectionChange): void {
        if (event.value)
            this.selected.push(event.row);
        else
            Util.removeItemFromArray(this.selected, event.row);

        this.updateSelectedAll();
    }

    private updateSelectedAll(): void {
        this.selectedAll = this.selected.length == (this._table.table.dataSource as MatTableDataSource<any>).data.length;
    }
}
