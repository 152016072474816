import { Injectable } from '@angular/core';
import { Menu } from './models/menu';
import { AuthService } from '@shared/data-access/auth/auth.service';
import { AppService } from '@shared/data-access/app/app.service';
import { User } from '@shared/data-access/auth/models/user';
import { INavLink } from './models/nav-link.model';
import { Category } from '@zj/paka-client';
import { AuthType, UserType } from '@zj/paka-client/users';

/**
 * Nav service
 */
@Injectable({ providedIn: 'root' })
export class NavService {
    constructor(
        private app: AppService,
        private auth: AuthService
    ) { }

    private readonly categoryLinks: INavLink[] = [
        {
            name: 'reports',
            url: 'reports'
        }
    ];

    private get user(): User {
        return this.auth.currentUser;
    }

    getCategoryMenus(): Menu[] {
        let categories: Category[] = this.app.currentClient?.categories ?? [];

        return categories.sort().map(c => {
            return new Menu(
                c,
                [
                    ...this.categoryLinks.map((link: INavLink) => ({
                        name: link.name,
                        url: `${c.toLowerCase()}/${link.url}`,
                        text: this.app.translate(`nav.${link.name}`)
                    }))
                ],
                this.app.translate(`nav.${c}`)
            );
        });
    }

    getCategoryNavLinks(): INavLink[] {
        let categories: Category[] = this.app.currentClient?.categories ?? [];

        return categories.sort().map(c => <INavLink>{
            name: this.app.translate(`nav.${c}}`),
            url: `${c.toLowerCase()}/reports`
        });
    }

    getArchivesMenu(): Menu {
        return this.getMenu(
            'archives',
            [
                {
                    name: 'packagesUsed',
                    url: 'archives/reports'
                },
                {
                    name: 'packagesRealized',
                    url: 'archives/packagesRealized'
                }
            ]
        )
    }

    getArchivesNavLink(): INavLink {
        return {
            name: 'archives',
            url: 'archives/reports'
        }
    }

    getEInvoiceNavLink(): INavLink {
        return {
            name: 'eInvoices',
            url: 'e-invoices'
        }
    }

    getUserMenu(): Menu {
        return this.getMenu(
            'user',
            [
                {
                    name: 'account',
                    url: 'account',
                    icon: 'account_circle'
                },
                {
                    name: 'help',
                    url: 'help',
                    icon: 'help'
                },
                {
                    name: 'changePassword',
                    url: 'account/change-password',
                    icon: 'lock',
                    visible: () => this.user.authType == AuthType.Forms && this.user.userType == UserType.Basic
                },
                {
                    name: 'logout',
                    url: 'auth/logout',
                    icon: 'logout'
                }
            ]
        );
    }

    private getMenu(name: string, items: INavLink[], sort: boolean = false): Menu {
        const menu = new Menu(name, items.map(item => {
            return {
                ...item,
                text: this.app.translate(`nav.${item.name}`),
                visible: item.visible ? item.visible : () => true
            }
        }));

        if (sort)
            menu.items.sort((a, b) => a.text.localeCompare(b.text));

        return menu;
    }
}
