import { Observable, Subject } from "rxjs";

export interface ITableColumnSettingsParameters {
    hidden?: boolean;
    locked?: boolean;
    display?: boolean;
}

export class TableColumnSettings {
    constructor(params?: ITableColumnSettingsParameters) {
        this.setParameters(params);
    }

    private _hidden!: boolean;
    private _locked!: boolean;
    private _display: boolean = true;

    private readonly _stateChanges: Subject<void> = new Subject<void>();

    get parameters(): ITableColumnSettingsParameters {
        return <ITableColumnSettingsParameters>{
            hidden: this._hidden,
            locked: this._locked,
            display: this.display
        }
    }

    get display(): boolean {
        return this._display;
    }

    set display(show: boolean) {
        if (show == this._display)
            return;

        this._display = show;

        this._stateChanges.next();
    }

    get hidden(): boolean {
        return this._hidden;
    }

    get locked(): boolean {
        return this._locked;
    }

    get stateChanges(): Observable<void> {
        return this._stateChanges.asObservable();
    }

    apply(params: ITableColumnSettingsParameters): void {
        this.setParameters(params);

        this._stateChanges.next();
    }

    private setParameters(params: ITableColumnSettingsParameters): void {
        this._locked = params?.locked ?? false;
        this._hidden = params?.hidden ?? false;
        this._display = params?.display ?? false;
    }
}
