import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, catchError, of, throwError, first } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { AppSettings } from '../app/models/app-settings';
import { User } from './models/user';
import { IAuthConfiguration } from './models/auth-configuration.model';
import { ILoginResponse } from './models/login-response.model';
import { ILogoutResponse } from './models/logout-response.model';
import { IPasswordSettings } from './models/password-settings.model';
import { IUserData } from './models/user-data.model';
import { Router } from '@angular/router';
import { Client } from '../client/models/client';
import { ClientClient, IClientResponseForUserTypeClient } from '@zj/paka-client/clients';
import { JwtPayload, jwtDecode } from 'jwt-decode';

const STORAGE_KEYS = {
    user: 'user',
    selectedClientId: 'selectedClientId',
    clients: 'clients',
    accessToken: 'auth.token',
    accessTokenExpires: 'auth.tokenExpires',
    refreshToken: 'auth.refreshToken',
    getAuth: 'auth.get',
    setAuth: 'auth.set',
    endAuth: 'auth.end'
};

export const anonymous = new User();

/**
 * Authentication service
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
    constructor(
        private http: HttpClient,
        private appSettings: AppSettings,
        private router: Router,
        private injector: Injector
    ) {
        this.storageKey = Date.now().toString();
        this.setupListeners();
    }

    static readonly STORAGE_KEY_PREFIX: string = 'client';

    get apiUrl(): string {
        return `${this.appSettings.apiUrl}/auth`;
    }
    get refreshUrl(): string {
        return `${this.apiUrl}/refresh`;
    }
    get loginUrl(): string {
        return `${this.apiUrl}/login`;
    }
    get logoutUrl(): string {
        return `${this.apiUrl}/logout`;
    }

    private _currentUser: User;
    get currentUser(): User {
        return this._currentUser || anonymous;
    }

    private _accessToken: string;
    get accessToken(): string {
        return this._accessToken;
    }

    private _refreshToken: string;
    get refreshToken(): string {
        return this._refreshToken;
    }

    get isAuthenticated(): boolean {
        return this.currentUser.isAuthenticated;
    }

    private _currentClient: Client;
    get currentClient(): Client {
        return this._currentClient;
    }

    private _clientClient: ClientClient;
    private get clientClient(): ClientClient {
        if (!this._clientClient) this._clientClient = this.injector.get(ClientClient);

        return this._clientClient;
    }

    private storage = sessionStorage;
    private storageKey: string;
    private accessTokenExpires?: Date;

    private userSubject: BehaviorSubject<User> = new BehaviorSubject<User>(anonymous);
    private clientSubject: BehaviorSubject<Client> = new BehaviorSubject<Client>(null);

    get currentClient$(): Observable<Client> {
        return this.clientSubject.asObservable();
    }

    get user$(): Observable<User> {
        return this.userSubject.asObservable();
    }

    accessTokenExpired(): boolean {
        return !this.accessTokenExpires || (this.accessTokenExpires.getTime() - new Date().getTime()) / 60000 < 1;
    }

    authenticate(callback: (user: User) => void) {
        if (this.isAuthenticated) {
            callback(this.currentUser);

            return;
        }

        // get from the current storage
        try {
            const user = this.createUser(this.getStorageItem<IUserData>(STORAGE_KEYS.user));

            if (user.id) {
                this.setUserAndAccess(user, {
                    token: this.getStorageItem(STORAGE_KEYS.accessToken),
                    refreshToken: this.getStorageItem(STORAGE_KEYS.refreshToken),
                    expires: new Date(+this.getStorageItem(STORAGE_KEYS.accessTokenExpires))
                });

                const clientId = this.getStorageItem<number>(STORAGE_KEYS.selectedClientId);

                this.setClient(this.createClient(this.getStorageClient(clientId)));
            }
        } catch (err) {
            this.clearSession();
        }

        if (this.isAuthenticated) {
            callback(this.currentUser);

            return;
        }

        // get from an other browser tab
        this.requestFromOtherSession()
            .pipe(first())
            .subscribe(user => callback(user));
    }

    getConfiguration(): Observable<IAuthConfiguration> {
        const url = `${this.apiUrl}/configuration`;

        return this.http.get<IAuthConfiguration>(url);
    }

    getLoginData(): Observable<IUserData> {
        const url: string = `${this.apiUrl}/loginData`;

        return this.http.get<IUserData>(url).pipe(
            tap((data) => {
                const user: User = this.createUser(data);

                this.setUser(user);
            })
        );
    }

    getPasswordSettings(): Observable<IPasswordSettings> {
        const url = `${this.apiUrl}/passwordSettings`;

        return this.http.get<IPasswordSettings>(url);
    }

    loginByCode(code: string): Observable<ILoginResponse> {
        return this.http.post<ILoginResponse>(this.loginUrl, { code }, { withCredentials: true }).pipe(
            tap((data) => {
                this.parseLoginResponse(data);
                return data;
            })
        );
    }

    loginByUserNameAndPassword(username: string, password: string): Observable<ILoginResponse> {
        return this.http.post<ILoginResponse>(this.loginUrl, { username, password }, { withCredentials: true }).pipe(
            tap((data) => {
                this.parseLoginResponse(data);
                return data;
            })
        );
    }

    logout(): Observable<ILogoutResponse> {
        return this.http.post<ILogoutResponse>(this.logoutUrl, null, { withCredentials: true })
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status == 401) {
                        return of({});
                    }

                    return throwError(err);
                }),
                tap((data) => {
                    this.clearAllSessions();

                    return data;
                })
            );
    }

    /**
     * Refresh user authentication.
     *
     * @param emit Emit refreshed user data to the user observables.
     *
     * @returns Access token.
     */
    refresh(emit: boolean = true): Observable<string> {
        return this.refreshWithToken(this._refreshToken, emit);
    }

    refreshWithToken(refreshToken: string, emit: boolean = true): Observable<string> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return <Observable<string>>this.http.post<ILoginResponse>(this.refreshUrl, JSON.stringify(refreshToken), {
            headers: headers,
            withCredentials: true
        }).pipe(
            map((data) => {
                this.parseLoginResponse(data, emit);

                return data.accessToken;
            })
        );
    }

    resetPassword(password: string): Observable<any> {
        const url = `${this.apiUrl}/password`;

        return this.http.post(url, { password });
    }

    resetPasswordWithSecret(password: string, secret: string): Observable<any> {
        const url = `${this.apiUrl}/password`;

        return this.http.post(url + '/secret', { password, secret });
    }

    setAccess(accessToken: string, refreshToken: string, expires?: Date): Observable<void> {
        if (!expires) {
            let decodedToken: JwtPayload = jwtDecode(accessToken);

            expires = new Date(0);
            expires.setUTCSeconds(decodedToken.exp);
        }

        this.setStorageItem(STORAGE_KEYS.accessToken, accessToken);
        this.setStorageItem(STORAGE_KEYS.accessTokenExpires, expires.getTime().toString());
        this.setStorageItem(STORAGE_KEYS.refreshToken, refreshToken);

        this._accessToken = accessToken;
        this._refreshToken = refreshToken;

        this.accessTokenExpires = expires;

        const url: string = `${this.apiUrl}/loginData`;

        return this.http.get<IUserData>(url).pipe(
            tap((data) => {
                const user: User = this.createUser(data);

                this.setUser(user);
            }),
            map((data) => { return; })
        );
    }

    clearClient(emit: boolean = true): void {
        this.setClient(null, emit);
    }

    changeClient(clientId: number, emit: boolean = true): Observable<void> {
        const client = this.getStorageClient(clientId);

        if (client) {
            this.setClient(this.createClient(client), emit);
            return of(undefined);
        }

        return this.clientClient.getById(clientId).pipe(
            tap((clientData: IClientResponseForUserTypeClient) => {
                this.setClient(this.createClient(clientData), emit);
            }),
            map(response => undefined)
        )
    }

    /**
     * Recover user password.
     *
     * @param userName User name of the user for which to recover password.
     */
    recoverPassword(userName: string): Observable<void> {
        const url: string = `${this.apiUrl}/password/recover`;

        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.post<void>(url, JSON.stringify(userName), {
            headers: headers,
            withCredentials: true
        });
    }

    private setClient(client: Client, emit: boolean = true): void {
        if (client) {
            let clients = this.getStorageItem<IClientResponseForUserTypeClient[]>(STORAGE_KEYS.clients) ?? [];

            if (!clients.some(cl => cl.id == client.id)) {
                clients.push(client);

                this.setStorageItem(STORAGE_KEYS.clients, clients);
            }
        }

        this.setStorageItem(STORAGE_KEYS.selectedClientId, client ? client.id : null);

        this._currentClient = client;

        if (emit) {
            this.clientSubject.next(this._currentClient);
        }
    }

    private static getStorageKey(storageKey: string): string {
        return `${AuthService.STORAGE_KEY_PREFIX}${storageKey}`;
    }

    /**
     * Clear all sessions.
     *
     * Clears sessions in all tabs.
     */
    private clearAllSessions() {
        this.clearSession();

        this.dispatchEvent(AuthService.getStorageKey(STORAGE_KEYS.endAuth), '1');
    }

    /**
     * Clear current session.
     *
     * Clears only the session in the active/current tab.
     */
    private clearSession() {
        this.setStorageItem(STORAGE_KEYS.clients, null);
        this.setClient(null);
        this.setUserAndAccess(anonymous, { token: undefined, refreshToken: undefined, expires: new Date() });
    }

    private parseLoginResponse(data: ILoginResponse, emit: boolean = true) {
        const user = this.createUser(data);

        this.setUserAndAccess(user, {
            token: data.accessToken,
            refreshToken: data.refreshToken,
            expires: new Date(data.accessTokenExpires)
        }, emit);

        this.shareAuth();
    }

    private createUser(data: IUserData): User {
        const user = new User();

        if (data) {
            user.userName = data.userName;
            user.person = data.person;
            user.userRole = data.userRole;
            user.userType = data.userType;
            user.id = data.id;
            user.authType = data.authType;
            user.clients = data.clients || [];
            user.mustResetPassword = data.mustResetPassword;
        }

        return user;
    }

    private createClient(clientData: IClientResponseForUserTypeClient): Client | null {
        if (!clientData || !clientData.id)
            return null;

        const client = new Client();

        client.id = clientData.id;
        client.name = clientData.name;
        client.registrationNumber = clientData.registrationNumber;
        client.vatNumber = clientData.vatNumber;
        client.legalStreet = clientData.legalStreet;
        client.legalCountry = clientData.legalCountry;
        client.legalCity = clientData.legalCity;
        client.legalDistrict = clientData.legalDistrict;
        client.legalParish = clientData.legalParish;
        client.physicalStreet = clientData.physicalStreet;
        client.physicalCountry = clientData.physicalCountry;
        client.physicalCity = clientData.physicalCity;
        client.physicalDistrict = clientData.physicalDistrict;
        client.physicalParish = clientData.physicalParish;
        client.bankAccount = clientData.bankAccount;
        client.bank = clientData.bank;
        client.categories = clientData.categories || [];
        client.contacts = clientData.contacts || [];

        return client;
    }

    private setUserAndAccess(user: User, accessToken: { token: string; refreshToken: string; expires?: Date }, emit: boolean = true) {
        this.setStorageItem(STORAGE_KEYS.accessToken, user ? accessToken.token : null);
        this.setStorageItem(STORAGE_KEYS.accessTokenExpires, user ? accessToken.expires.getTime().toString() : null);
        this.setStorageItem(STORAGE_KEYS.refreshToken, user ? accessToken.refreshToken : null);

        this._accessToken = accessToken.token;
        this._refreshToken = accessToken.refreshToken;

        this.accessTokenExpires = accessToken.expires;

        this.setUser(user, emit);
    }

    private setUser(user: User, emit: boolean = true): void {
        this.setStorageItem(STORAGE_KEYS.user, user);

        this._currentUser = user;

        if (emit) {
            this.userSubject.next(user);
        }
    }

    private requestFromOtherSession(): Observable<User> {
        const subj = new Subject<User>();
        const getKey = `${AuthService.getStorageKey(STORAGE_KEYS.getAuth)}${this.storageKey}`;

        this.dispatchEvent(getKey, '1');

        setTimeout(() => {
            subj.next(this.isAuthenticated ? this.currentUser : anonymous);
        }, 100);

        return subj.asObservable();
    }

    /**
     * Setup dispatched auth event listeners.
     */
    private setupListeners() {
        window.addEventListener('storage', (event) => {
            if ((event.key || '').indexOf(AuthService.getStorageKey(STORAGE_KEYS.getAuth)) === 0)
                this.handleGetAuthEvent(event);
            else if (event.key === AuthService.getStorageKey(STORAGE_KEYS.setAuth))
                this.handleSetAuthEvent(event);
            else if (event.key === AuthService.getStorageKey(STORAGE_KEYS.endAuth))
                this.handleEndAuthEvent(event);
        });
    }

    /**
     * Dispatch an auth related event.
     */
    private dispatchEvent(event: string, data: string) {
        localStorage.setItem(event, data);
        localStorage.removeItem(event);
    }

    /**
     * Share current authentication with other tabs.
     */
    private shareAuth() {
        this.dispatchEvent(
            AuthService.getStorageKey(STORAGE_KEYS.setAuth),
            JSON.stringify({
                user: this.getStorageItem(STORAGE_KEYS.user),
                token: this.accessToken,
                refreshToken: this.refreshToken,
                expires: this.accessTokenExpires,
                clients: this.getStorageItem<IClientResponseForUserTypeClient[]>(STORAGE_KEYS.clients) ?? [],
            })
        );
    }

    private handleGetAuthEvent(event: StorageEvent): void {
        this.shareAuth();
    }

    private handleSetAuthEvent(event: StorageEvent): void {
        if (!event.newValue)
            return;

        const data = JSON.parse(event.newValue);
        const user = this.createUser(<IUserData>data.user);

        this.setUserAndAccess(user, {
            token: data.token,
            refreshToken: data.refreshToken,
            expires: new Date(data.expires)
        });

        this.setStorageItem(STORAGE_KEYS.clients, data.clients);
    }

    private handleEndAuthEvent(event: StorageEvent): void {
        if (!this.isAuthenticated)
            return;

        this.clearSession();

        const currentUrl: string = this.router.url;

        this.router.navigate(['auth', 'login'],
            {
                queryParams: { returnUrl: currentUrl }
            }
        );
    }

    private getStorageClient(clientId: number | null): IClientResponseForUserTypeClient | null {
        if (!clientId)
            return null;

        const clients = this.getStorageItem<IClientResponseForUserTypeClient[]>(STORAGE_KEYS.clients) ?? [];

        return clients.find(cl => cl.id == clientId)
    }

    private getStorageItem<T = string>(key: string): T {
        const storageItem = this.storage.getItem(AuthService.getStorageKey(key));

        try {
            return JSON.parse(storageItem) as T;
        } catch (err) {
            return storageItem as T;
        }
    }

    private setStorageItem<T>(key: string, value: T): void {
        if (value) {
            this.storage.setItem(
                AuthService.getStorageKey(key),
                typeof value === 'string' ? value : JSON.stringify(value)
            );
        } else {
            this.storage.removeItem(AuthService.getStorageKey(key));
        }
    }
}
