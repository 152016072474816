<footer class="site-footer">
    <div class="container">
        <div class="row">
            <div class="col contact-info">
                <div class="col-6">
                    <a href="tel:{{ 'common.phoneNumber' | translate }}">
                        {{ "common.phoneNumber" | translate }}
                    </a>
                </div>
                <div class="col-6">
                    <a href="mailto:{{ 'common.mailToEmail' | translate }}">
                        {{ "common.mailToEmail" | translate }}
                    </a>
                </div>
                <div class="col-6">{{ "common.workingHours" | translate }}</div>
                <div class="col-6">{{ "common.address" | translate }}</div>
                <div class="col-12">{{ "common.copyright" | translate | formatString : [2024] }}</div>
            </div>
            <div class="col h-end v-center">
                <a [routerLink]="['/']" class="logo">
                    <img src="assets/img/logo.svg" alt="logo" />
                </a>
            </div>
        </div>
    </div>
</footer>
