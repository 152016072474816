<mat-form-field [class.inline]="inline">
    <mat-label *ngIf="!inline">{{ label | translate }}</mat-label>

    <input matInput [matDatepicker]="pickerDate" [ngModel]="value" (ngModelChange)="setValue($event)"
        [disabled]="isDisabled" [required]="isRequired" (blur)="onBlur()" [errorStateMatcher]="errorStateMatcher" />

    <mat-datepicker-toggle matSuffix [for]="pickerDate"></mat-datepicker-toggle>
    <mat-datepicker #pickerDate></mat-datepicker>

    <button *ngIf="clearEnabled && value" mat-icon-button matSuffix (click)="setValue(null)" type="button">
        <mat-icon>clear</mat-icon>
    </button>
</mat-form-field>
