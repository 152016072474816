import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { WindowToken } from '@core/window-token';
import { LOCATION_INITIALIZED, LocationStrategy } from '@angular/common';
import { MAT_LEGACY_PAGINATOR_DEFAULT_OPTIONS as MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/legacy-paginator';
import { AuthService } from '@shared/data-access/auth/auth.service';
import { AppService } from '@shared/data-access/app/app.service';
import { AppSettings } from './models/app-settings';
import { HttpClient } from '@angular/common/http';
import { ParameterClient, ParameterCode } from '@zj/paka-client/parameters';

/**
 * Application initialization service
 */
@Injectable({ providedIn: 'root' })
export class AppInitService {
    constructor(
        private app: AppService,
        private appSettings: AppSettings,
        private auth: AuthService,
        private router: Router,
        private injector: Injector,
        private http: HttpClient,
        private locationStrategy: LocationStrategy,
        @Inject(WindowToken) private window: Window
    ) { }

    /**
     * Initialize the application.
     * Setup language and authentication.
     */
    initialize() {
        return () => new Promise<any>((resolve: any) => {
            // Wait for language translation to be initialized.
            // This solves the problem with instant translations.
            // See https://github.com/ngx-translate/core/issues/517
            const locationInitialized = this.injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            const paginatorOptions = this.injector.get(MAT_PAGINATOR_DEFAULT_OPTIONS);

            const loading = this.app.showLoading();

            this.http.get('assets/appsettings.json').subscribe(response => {
                var readSettings = <AppSettings>response;

                this.appSettings.apiUrl = readSettings.apiUrl;
                this.appSettings.defaultLanguage = readSettings.defaultLanguage;
                this.appSettings.production = readSettings.production;

                locationInitialized.then(() => {
                    const langToSet = this.app.getUserLanguage();
                    this.app.translator.setDefaultLang(langToSet);
                    this.app.translator.use(langToSet).subscribe(
                        () => {

                            this.auth.authenticate(() => {
                                if (!this.auth.currentUser.isAuthenticated && !window.location.href.toLowerCase().includes('/auth/')) { // prevent loops
                                    // login automatically
                                    this.app.hideLoading(loading);

                                    resolve(null);

                                    const nextUrl: string = this.window.location.pathname.replace(
                                        this.locationStrategy.getBaseHref().replace(/^\/+|\/+$/g, ''), '')
                                        .replace(/\/\//, '/');

                                    this.router.navigateByUrl(`/auth/login?returnUrl=${nextUrl}`);
                                } else {
                                    const parameters: ParameterClient = new ParameterClient(this.appSettings.apiUrl, this.http);

                                    parameters.reset();
                                    parameters.get().subscribe(data => {
                                        const pageSize = parameters.findValue(ParameterCode.PageSize, data);

                                        if (paginatorOptions && pageSize) {
                                            paginatorOptions.pageSize = +pageSize;
                                        }

                                        this.app.hideLoading(loading);
                                        resolve(null);
                                    });
                                }
                            });
                        },
                        (err) => {
                            this.app.hideLoading(loading);

                            resolve(null);
                        }
                    );
                });
            });
        });
    }
}
