<mat-form-field [class.inline]="inline">
    <mat-label *ngIf="!inline">{{ label | translate }}</mat-label>

    <input matInput appOnlyNumber [ngModel]="valueManager.displayValue" (ngModelChange)="setValue($event)"
        [decimalPlaces]="decimalPlaces" [disabled]="isDisabled" [integerOnly]="integerOnly"
        [positiveOnly]="positiveOnly" [required]="isRequired" (blur)="onBlur()"
        [errorStateMatcher]="errorStateMatcher" />

    <button *ngIf="clearEnabled && valueManager.displayValue" mat-icon-button matSuffix (click)="setValue(null)" type="button">
        <mat-icon>clear</mat-icon>
    </button>
</mat-form-field>
