import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrayJoin'
})
export class ArrayJoinPipe implements PipeTransform {
    transform<T>(array: T[], separator: string = ', ', mapFn?: string | ((item: T) => any)): string {
        let finalArr: T[];

        if (mapFn) {
            const map = typeof mapFn === 'string' ? (t) => t[mapFn] : mapFn;
            finalArr = array.map(map);
        } else {
            finalArr = array;
        }

        finalArr = finalArr.filter(t => !!t);

        return (finalArr || []).join(separator);
    }
}
