<h1 mat-dialog-title>
    {{
        (initialValue ? "hyperlink.editTitle" : "hyperlink.addTitle")
            | translate
    }}
</h1>

<div mat-dialog-content>
    <form #form="ngForm">
        <mat-form-field>
            <mat-label>{{ "hyperlink.hyperlink" | translate }}</mat-label>
            <input
                type="url"
                matInput
                name="hyperlink"
                [(ngModel)]="hyperlink"
                maxlength="250"
                [pattern]="pattern"
                required
            />
            <mat-hint>{{ "hyperlink.hint" | translate }}</mat-hint>
        </mat-form-field>
    </form>
</div>

<div mat-dialog-actions>
    <button
        mat-flat-button
        color="primary"
        [disabled]="!form.valid"
        (click)="continue()"
    >
        {{ (initialValue ? "btn.continue" : "btn.add") | translate }}
    </button>
    <button mat-stroked-button (click)="cancel()">
        {{ "btn.cancel" | translate }}
    </button>
</div>
