import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '@shared/data-access/auth/auth.service';
import { Category } from '@zj/paka-client';

@Injectable()
export class UserClientGuard {
    constructor(
        private auth: AuthService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const userClients = this.auth.currentUser.clients;
        const currentClient = this.auth.currentClient;
        const category: Category = route.data.category;

        if (!currentClient || !userClients.some(t => t.id == currentClient.id)) {
            this.auth.clearClient();
            this.router.navigate(['/auth/set-client'], { queryParams: { returnUrl: '/' + state.url } });
            return;
        }

        if (category && !currentClient.categories.includes(category)) {
            this.router.navigate(['/']);
            return;
        }

        return true;
    }
}
