import { Category, IClassifier } from "@zj/paka-client";

/**
 * Client data.
 */
export class Client {
    id: number;
    name: string;
    registrationNumber: string;
    vatNumber: string;
    legalStreet: string;
    legalCountry: IClassifier;
    legalCity: IClassifier;
    legalDistrict: IClassifier;
    legalParish: IClassifier;
    legalPostalCode: string | null;
    physicalStreet: string;
    physicalCountry: IClassifier;
    physicalCity: IClassifier;
    physicalDistrict: IClassifier;
    physicalParish: IClassifier;
    physicalPostalCode: string | null;
    bankAccount: string;
    bank: IClassifier;
    categories: Category[];
    contacts: IClientContactData[];

    get legalAddress(): string {
        return [
            this.legalStreet, this.legalCity?.value, this.legalParish?.value,
            this.legalDistrict?.value, this.legalPostalCode, this.legalCountry?.value
        ].filter((t) => !!t).join(', ');
    }

    get physicalAddress(): string {
        return [
            this.physicalStreet, this.physicalCity?.value, this.physicalParish?.value,
            this.physicalDistrict?.value, this.physicalPostalCode, this.physicalCountry?.value
        ].filter((t) => !!t).join(', ');
    }
}

/* Inner interfaces */

/**
 * Client data {@link Client} contact data.
 */
export interface IClientContactData {
    phoneNumber: string;
    mobilePhone: string;
    email: string;
}
