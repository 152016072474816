import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective {
    @Input() decimalPlaces: number | null = null;
    @Input() integerOnly: boolean = false;
    @Input() positiveOnly: boolean = false;

    private navigationKeys = [
        'Backspace', 'Delete', 'Tab', 'Escape', 'Enter', 'Home', 'End', 'ArrowLeft', 'ArrowRight', 'Clear', 'Copy', 'Paste'
    ];

    constructor(public el: ElementRef) { }

    @HostListener('keydown', ['$event'])
    onKeyDown(e: KeyboardEvent) {
        if (this.navigationKeys.indexOf(e.key) > -1 || (e.key === 'a' && e.ctrlKey === true) || (e.key === 'c' && e.ctrlKey === true)) {
            return;
        }

        if (this.navigationKeys.indexOf(e.key) > -1 ||
            (e.key === 'a' && e.ctrlKey === true) ||
            (e.key === 'c' && e.ctrlKey === true) ||
            (e.key === 'v' && e.ctrlKey === true)) {
            return;
        }

        let current: string = this.el.nativeElement.value;
        let next: string = current.concat(e.key);

        let sign = this.positiveOnly ? '' : '-?';
        let decimal = this.integerOnly ? '' : '[\\.,]?\\d{0,' + (this.decimalPlaces || '') + '}';
        let regex = new RegExp(`^${sign}\\d*${decimal}$`, 'g');

        if (next && !String(next).match(regex)) {
            e.preventDefault();
        }
    }
}

