import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '@shared/components/pages/not-found.component';
import { UserClientGuard } from '@shared/guards/user-client.guard';
import { UserRoleGuard } from '@shared/guards/user-role.guard';
import { Category } from '@zj/paka-client';

export const routes: Routes = [
    {
        path: 'main',
        canActivate: [UserRoleGuard, UserClientGuard],
        loadChildren: () => import('./features/main/main.module').then((module) => module.MainPageModule)
    },
    {
        path: 'auth',
        loadChildren: () => import('./features/auth/auth.module').then((module) => module.AuthModule)
    },
    {
        path: 'account',
        canActivate: [UserRoleGuard, UserClientGuard],
        loadChildren: () => import('./features/account/account.module').then((module) => module.AccountModule)
    },
    {
        path: 'archives',
        canActivate: [UserRoleGuard, UserClientGuard],
        loadChildren: () => import('./features/archives/archives.module').then((module) => module.ArchivesModule)
    },
    {
        path: 'help',
        canActivate: [UserRoleGuard],
        loadChildren: () => import('./features/help/help.module').then((module) => module.HelpModule)
    },
    {
        path: 'tks',
        canActivate: [UserRoleGuard, UserClientGuard],
        data: { category: Category.TKS },
        loadChildren: () => import('./features/tks/tks.module').then((module) => module.TksModule)
    },
    {
        path: 'e-invoices',
        canActivate: [UserRoleGuard, UserClientGuard],
        loadChildren: () => import('./features/e-invoice/e-invoice.module').then((module) => module.EInvoiceModule)
    },
    {
        path: '',
        redirectTo: '/main',
        pathMatch: 'full'
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        onSameUrlNavigation: 'reload'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
