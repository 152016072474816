import { Component, ElementRef, Inject, Input, OnDestroy, Optional, Self, ViewChild } from "@angular/core";
import { ControlValueAccessor, FormBuilder, NgControl } from "@angular/forms";
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from "@angular/material/form-field";
import { FocusMonitor } from "@angular/cdk/a11y";
import { Subject } from "rxjs";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { MatLegacySelectChange } from "@angular/material/legacy-select";
import { Locale, LocalizationSubject, supportedLocales } from "@zj/paka-client";

@Component({
    selector: 'app-localization-subject-input',
    templateUrl: './localization-subject-input.component.html',
    styleUrls: ['./localization-subject-input.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: LocalizationSubjectInputComponent }],
    host: {
        '[class.example-floating]': 'shouldLabelFloat',
        '[id]': 'id',
    },
})
export class LocalizationSubjectInputComponent implements ControlValueAccessor, MatFormFieldControl<LocalizationSubject>, OnDestroy {
    constructor(
        private _formBuilder: FormBuilder,
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    static nextId = 0;

    @ViewChild('locale') localeSelect: HTMLSelectElement;
    @ViewChild('value') valueInput: HTMLInputElement;

    parts = this._formBuilder.group({
        locale: [supportedLocales[0]],
        value: [''],
    });
    stateChanges = new Subject<void>();
    focused = false;
    touched = false;
    controlType = 'localization-subject-input';
    id = `localization-subject-input-${LocalizationSubjectInputComponent.nextId++}`;
    onChange = (_: any) => { };
    onTouched = () => { };

    protected activeLocale: Locale = supportedLocales[0];
    protected localizationSubject: LocalizationSubject = new LocalizationSubject();

    protected readonly supportedLocales: Locale[] = supportedLocales;

    get empty() {
        const {
            value: { locale, value },
        } = this.parts;

        return !locale && !value;
    }

    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input('aria-describedby') userAriaDescribedBy: string;

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }
    private _placeholder: string;

    @Input()
    get required(): boolean {
        return this._required;
    }
    set required(value: BooleanInput) {
        this._required = coerceBooleanProperty(value);
        this.stateChanges.next();
    }
    private _required = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.parts.disable() : this.parts.enable();
        this.stateChanges.next();
    }
    private _disabled = false;

    @Input()
    get value(): LocalizationSubject | null {
        if (!this.parts.valid)
            return null;

        return this.localizationSubject.values.length == 0 ? null : this.localizationSubject;
    }
    set value(localizationSubject: LocalizationSubject | null) {
        if (!localizationSubject) {
            this.localizationSubject = new LocalizationSubject();

            return;
        }

        this.localizationSubject = localizationSubject;

        this.parts.controls.value.setValue(localizationSubject.values.find(t => t.locale == this.parts.value.locale)?.value)

        this.stateChanges.next();
    }

    get errorState(): boolean {
        if (!this.touched)
            return false;

        if (this.parts.invalid)
            return true;

        return false;
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef);
    }

    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    setDescribedByIds(ids: string[]) {
        const controlElement = this._elementRef.nativeElement.querySelector(
            '.localization-subject-container',
        )!;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick() {
        this._focusMonitor.focusVia(this.valueInput, 'program');
    }

    writeValue(localizationSubject: LocalizationSubject | null): void {
        this.value = localizationSubject;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    protected input(): void {
        this.localizationSubject.addOrUpdateValue(this.activeLocale, this.parts.value.value);

        this.onChange(this.value);
    }

    protected selectionChange(event: MatLegacySelectChange): void {
        this.localizationSubject.addOrUpdateValue(this.activeLocale, this.parts.value.value);

        this.activeLocale = event.value;

        this.parts.controls.value.setValue(this.localizationSubject.getValue(this.activeLocale));

        this.onChange(this.value);
    }
}
