<div *ngIf="!inline" class="{{ displayClass }}">
    <label>{{ label | translate }}</label>

    <div>
        <ng-container [ngTemplateOutlet]="displayTemplate ? displayTemplate : defaultTemplate"
            [ngTemplateOutletContext]="{option: value, value: value}">
        </ng-container>
    </div>
</div>

<div *ngIf="inline">
    <ng-container [ngTemplateOutlet]="displayTemplate ? displayTemplate : defaultTemplate"
        [ngTemplateOutletContext]="{option: value, value: value}">
    </ng-container>
</div>

<ng-template #defaultTemplate let-value="value">{{ value }}</ng-template>
