import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from '../data-access/app/app.service';

@Pipe({
    name: 'appPickLocalizedString' // "date" pipe is already reserved
})
export class PickLocalizedStringPipe implements PipeTransform {
    constructor(private app: AppService) {
    }

    transform(value: any): any {
        if (!value)
            return null;

        return this.app.pickLocalizedString(value);
    }
}
